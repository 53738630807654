<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
export default {
  props: {
    data: {
      type: Object,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorList: ["#9E87FF", "#73DDFF", "#fe9a8b", "#F56948", "#9E87FF"],
      myChart: "",
      list: [],
      xdata: [],
      ydata: [],
      option: {
        legend: {
          icon: "circle",
          top: "5%",
          right: "5%",
          itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0,
            },
            lineStyle: {
              width: 0,
            },
          },
          backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c",
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
        },
        grid: {
          top: "15%",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            boundaryGap: true,
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#fff", //坐标轴字颜色
              },
              margin: 15,
            },
            axisPointer: {
              label: {
                padding: [11, 5, 7],
                backgroundColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#fff", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#fff", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#33c0cd", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#33c0cd", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: ['#4F5663'],
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            data: [],
            symbolSize: 1,
            symbol: "circle",
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 2,
              color: "#9E87FF",
            },
            itemStyle: {
              normal: {
                color: "#9E87FF",
                borderColor: "#9E87FF",
              },
            },
          },
          {
            name: "",
            type: "line",
            data: [],
            symbolSize: 1,
            symbol: "circle",
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 2,
              color: "#73DDFF",
            },
            itemStyle: {
              normal: {
                color: "#73DDFF",
                borderColor: "#73DDFF",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听

    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      console.log(e);

      this.$emit("click", e);
    },
    updateOption() {
      this.option.xAxis[0].data = this.list.xname;
      this.option.series[0].data = this.list.list1;
      this.option.series[1].data = this.list.list2;
      this.option.series[0].name = this.list.title[0];
      this.option.series[1].name = this.list.title[1];
      this.myChart.setOption(this.option);
    },
    getEchartData() {
      this.option.xAxis[0].data = this.list.xname;
      this.option.series[0].data = this.list.list1;
      this.option.series[1].data = this.list.list2;
      if (typeof this.list.title == "array") {
        this.option.series[0].name = this.list.title[0] || "";
        this.option.series[1].name = this.list.title[1] || "";
      }

      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>