<template>
  <div class="wrap">
    <div class="flex">
      <!-- left -->
      <div class="sider">
        <div class="data-card no-pb">
          <div class="card-title">成交趋势图</div>
          <div class="side-echart">
            <div style="height: 250px">
              <line-echarts
                ename="payLine"
                :data="orderTrendData"
              ></line-echarts>
            </div>
          </div>
        </div>
        <div class="data-card no-pb">
            <div class="card-title">订单数据</div>
            <div style="height: 250px">
              <line-echarts
                :onclick="true"
                @click="handlePayClick"
                ename="payLine"
                :data="PayLineData"
              ></line-echarts>
            </div>
          </div>
        <div class="data-card no-pb">
          <div class="card-title">订单构成</div>
          <div style="height: 260px">
            <orderTypeEcharts
              ename="ordertype"
              :data="orderTypeData"
            ></orderTypeEcharts>
          </div>
        </div>
      </div>
      <!-- center  -->
      <div class="center">
        <div class="center-info flex jc-b al">
          <div @click="openTime">
            开播时间：<span style="color: #6d9fff">{{ start_time }}</span>
          </div>
          <div class="c-info-right">
            直播结束后以直播数据为准
            <svg-icon
              :name="is_scrollfull ? 'exit-fullscreen' : 'fullscreen'"
              class="screenfull-svg"
              @click="scrollfulle"
            />
          </div>
        </div>
        <!-- 主数据板 -->
        <div class="center-top">
          <div>
            <div class="total count-item">
              <div class="data-count-name">共计收益（元）</div>
              <div class="data-count-count" style="font-size: 36px">
                {{ dyOrder.trading_amount || 0 }}
              </div>
            </div>
          </div>
          <div class="count-box1">
            <div class="count-item">
              <div class="data-count-name">成交量</div>
              <div class="data-count-count">
                {{ dyOrder.trading_count || 0 }}
              </div>
            </div>
            <div class="count-item">
              <div class="data-count-name">成交人数</div>
              <div class="data-count-count">
                {{ dyOrder.trading_people || 0 }}
              </div>
            </div>
            <div class="count-item">
              <div class="data-count-name">成交率</div>
              <div class="data-count-count">{{ closeRate }}</div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="center-1 flex">
          <div class="data-card" style="flex: 1">
            <div class="card-title">用户浏览商品数据</div>
            <scroll-list
              :listName="viewListName"
              :list="viewList"
              :option="viewOption"
              sname="view"
              :headStyle="headStyle"
            :itemStyle="itemStyle"
            ></scroll-list>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="sider">
        <div class="data-card">
          <div class="card-title">成交用户画像</div>
          <div>用户性别分布</div>
          <div style="height: 180px">
            <someCircleEcharts ename="sex" :data="sexInfo"></someCircleEcharts>
          </div>
        </div>
        <div class="data-card">
          <div class="card-title">用户实时在线时间</div>
          <scroll-list
            :listName="timeListName"
            :list="timeList"
            :option="timeOption"
            sname="online"
            :headStyle="headStyle"
            :itemStyle="itemStyle"
          ></scroll-list>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="开播时间">
          <el-date-picker
            v-model="start_time_temp"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="end_time_temp"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import lineEcharts from "../../components/echarts/lineEcharts.vue";
import someCircleEcharts from "../../components/echarts/someCircleEcharts.vue";
import ScrollList from "../../components/scrollList/scrollList.vue";
import { get2Time , DebounceBy } from "@/js/utils";
import scrollFull from "../../components/scrollFull/scrollFull.vue";
import orderTypeEcharts from "../../components/echarts/orderTypeEcharts.vue";
import { createSocket, sendWSPush, closeWs } from "@/js/webSocket";
export default {
  data() {
    return {
      dialogVisible: false,
      viewListName: ["用户", "商品", "时间", "时长"],
      viewList: [],
      viewOption: ["user_name", "commodity_name", "start_time", "time"],
      timeListName: ["用户", "时间"],
      timeOption: ["user_name", "time"],
      timeList: [],
      dyOrder: {},
      sexInfo: [],
      orderTrendData:{},
      PayAndUnpaid: {},
      PayLineData: {},
      start_time_temp: "",
      end_time_temp: "",
      start_time: new Date().Format("yyyy-MM-dd") + " 00:00:00",
      end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      timer: "",
      orderTypeData: [],
      is_scrollfull: false,
      closeRate:'0%',
      headStyle:{
        background:"#4F5663",
        color:'#fff',
        borderRadius: '10px 10px 0px 0px',
        border:'none'
      },
      itemStyle:{
        color:'#fff',
        borderBottom:'1px solid #4F5663'
      }
    };
  },
  components: {
    ScrollList,
    lineEcharts,
    someCircleEcharts,
    scrollFull,
    orderTypeEcharts,
  },
  destroyed() {
    closeWs()
    this.$store.commit("setProp", {
        prop: "headerShow",
        value: true,
      });
      this.$store.commit("setProp", {
        prop: "menuShow",
        value: true,
      });
  },
  mounted() {
    this.getData();
   setTimeout(()=>{
    this.wsConnect()
   },2000)
  },

  methods: {
    getCloseRate(){
      let rate = '0%'
        if(this.PayLineData.list2 && this.PayLineData.list1){
          let pay = 0;
          let unpay = 0;
          for (let index = 0; index < this.PayLineData.list1.length; index++) {
            pay += parseInt(this.PayLineData.list1[index]) ;
            unpay += parseInt(this.PayLineData.list2[index]) ;
          }
         if(pay > 0 && unpay > 0){
          rate = (pay / (pay+unpay) *100).toFixed(2) + '%'
         }
        }
        this.closeRate =  rate
    },
    handlePayClick(e) {
      console.log(this.PayLineData.origin[e.dataIndex]);
      this.$router.push({
        name: "dyPayOderList",
        query: { time: this.PayLineData.origin[e.dataIndex] },
      });
    },
    scrollfulle() {
      this.$store.commit("setProp", {
        prop: "headerShow",
        value: this.is_scrollfull,
      });
      this.$store.commit("setProp", {
        prop: "menuShow",
        value: this.is_scrollfull,
      });
      this.is_scrollfull = !this.is_scrollfull;
    },
    getData() {
      this.dyOrderInfo();
      this.PayAndUnpaidInfo();
      this.dyGenderInfo();
      this.getUserLoginTime();
      this.getviewTime();
      this.dyOrderConstitute()
      this.dyOrderSaleTrend()
    },
    confirTime() {
      this.dialogVisible = false;
      this.start_time_temp && (this.start_time = this.start_time_temp);
      this.end_time_temp && (this.end_time = this.end_time_temp);
      this.getData();
    },
    openTime() {
      this.dialogVisible = true;
    },
    // 订单构成
    dyOrderConstitute() {
      this.$axios({
        url: "user/rechargerecord/dyOrderConstitute",
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
        },
      }).then((res) => {
       let result = res.data.data;
        let Names = {
          consume: "消费金",
          member: "会员卡",
          coupon: "优惠券",
        };
        let List = [];
        for (let key in result) {
          this.todayTotal += result[key];
          List.push({
            name: Names[key],
            value: result[key],
          });
        }
        this.orderTypeData = List;
      });
    },

    // 用户在线时长
    getUserLoginTime() {
      this.$axios({
        url: "user/userOnlineRecord/queryListPage",
        params: {
          pageSize: 20,
          currentPage: 1,
          range_start_time: this.start_time,
          range_end_time: this.end_time,
        },
      }).then((res) => {
        res.data.data.forEach((item) => {
          let st = new Date(item.start_time);
          let endt = new Date(item.end_time);
          if (endt < st) {
            item.time = "在线";
          } else {
            item.time = get2Time(endt - st);
          }
        });
        this.timeList = res.data.data;
      });
    },
    // 用户浏览记录时长
    getviewTime() {
      this.$axios({
        url: "user/userCommodityRecord/queryListPage",
        params: {
          pageSize: 5,
          currentPage: 1,
          range_start_time: this.start_time,
          range_end_time: this.end_time,
        },
      }).then((res) => {
        res.data.data.forEach((item) => {
          let st = new Date(item.start_time);
          let endt = new Date(item.end_time);
          if (endt < st) {
            item.time = "浏览中";
          } else {
            item.time = get2Time(endt - st);
          }
        });
        this.viewList = res.data.data;
      });
    },
     // 成交趋势
     dyOrderSaleTrend() {
      this.$axios({
        url: "user/rechargerecord/dyOrderSaleTrend",
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
        },
      }).then((res) => {
        let payArr = {
          xname: [],
          list1: [],
          list2: [],
          title: ["成交单数", "下单人数"],
          origin: [],
        };
        res.data.data.forEach((item) => {
          payArr.origin.push(item.date);
          payArr.xname.push(item.date.split(" ")[1] + ":00");
          payArr.list1.push(item.order_number);
          payArr.list2.push(item.people_number);
        });
        this.orderTrendData = payArr;
      });
    },
    // 待支付和已支付
    PayAndUnpaidInfo() {
      this.$axios({
        url: "user/rechargerecord/dyOrderStatisticsByPayAndUnpaid",
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
        },
      }).then((res) => {
        this.PayAndUnpaid = res.data.data;
        let payArr = {
          xname: [],
          list1: [],
          list2: [],
          title: ["客户支付", "待支付"],
          origin: [],
        };
        res.data.data.forEach((item) => {
          payArr.origin.push(item.date);
          payArr.xname.push(item.date.split(" ")[1] + ":00");
          payArr.list1.push(item.pay_count);
          payArr.list2.push(item.unpaid_count);
        });
        this.PayLineData = payArr;
        this.getCloseRate()
      });
    },
    // 抖音性别数据
    dyGenderInfo() {
      this.$axios({
        url: "user/rechargerecord/dyOrderSexStatistics",
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
        },
      }).then((res) => {
        let data = res.data.data;
        let t = 0;
        for (let k in data) {
          t += data[k];
        }
        let value = 0;
        value = ((data.man / t) * 100).toFixed(1);
        this.$set(this.sexInfo, 0, {
          name: "男",
          value,
          count: data.man,
        });
        value = ((data.girl / t) * 100).toFixed(1);
        this.$set(this.sexInfo, 1, {
          name: "女",
          value,
          count: data.girl,
        });
      });
    },
    // 抖音订单数据
    dyOrderInfo() {
      this.$axios({
        url: "user/rechargerecord/dyOrderStatistics",
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
        },
      }).then((res) => {
        this.dyOrder = res.data.data;
      });
    },
    // 用户支付回调，获取数据
    PayCallback(){
      this.dyOrderInfo();
      this.PayAndUnpaidInfo();
      this.dyGenderInfo();
      this.dyOrderConstitute()
      this.dyOrderSaleTrend()
    },

    wsConnect() {
      createSocket();
      // 注册监听事件
      window.addEventListener("onmessageWS", this.getsocketData);
    },

    getsocketData(e) {
        // 创建接收消息函数
        let data = e && e.detail.data;
        console.log(data);
        let result = JSON.parse(data);
        let info =  JSON.parse(result.json)
        if(result.type === 1){
          // 待支付订单
          let date = new Date().Format('yyyy-MM-dd hh')
          let index = this.PayLineData.origin.indexOf(date)
          if(index != -1){
            let count = parseInt(this.PayLineData.list2[index]) + info.count
            this.PayLineData.list2.splice(index,1,count)
          }else{
            this.PayLineData.origin.push(date);
            this.PayLineData.xname.push(date.split(" ")[1] + ":00");
            this.PayLineData.list2.push(info.count);
          }
          this.getCloseRate()
        }else if(result.type === 2){
          DebounceBy(this.PayCallback,2000)
        }else if(result.type === 3){
          let st = new Date(info.start_time);
          let endt = new Date(info.end_time);
          if (endt < st) {
            info.time = "浏览中";
          } else {
            info.time = get2Time(endt - st);
          }
          info.start_time = new Date(st).Format('yyyy-MM-dd hh:mm:ss')
          this.viewList.push(info)
        }else if(result.type === 4){
          let st = new Date(info.start_time);
          let endt = new Date(info.end_time);
          if (endt < st) {
            info.time = "在线";
          } else {
            info.time = get2Time(endt - st);
          }
          this.timeList.push(info)
        }
      },
    handleClose() {
      //想服务器发送断开连接的信息
      let msg = { event: "stop_screen", to_user: this.userId };
      sendWSPush(msg);
      closeWs();
      this.dialogVisible = false;
      window.removeEventListener("onmessageWS", this.getsocketData);
    },
    screenSharing(userid) {
      this.userId = userid;
      this.wsConnect();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.wrap{
  background: #282E39;
  padding: 20px;
}
.sider {
  width: 20%;
}
.center {
  flex: 1;
  margin: 0 20px;
}
.data-card {
  padding: 20px;
  background: #383E48;
  border-radius: 25px;
  margin-bottom: 20px;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
}
.card-title {
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &::before {
    display: flex;
    content: "";
    width: 4px;
    height: 10px;
    background: #ff8711;
    border-radius: 2px;
    margin-right: 10px;
  }
}

.center-top {
  background-color: #ff7e00;
  background-image: url('../../assets/imgs/dy_top_bg.png');
  padding: 30px;
  border-radius: 6px;
}
.center-1 {
  width: 100%;
  margin-top: 20px;
}

.data-desc-list {
  display: flex;
  flex-wrap: wrap;
}
.data-desc-item {
  width: 50%;
  margin-bottom: 20px;
  .desc-name {
    font-size: 14px;
    color: #7e84a3;
  }
  .desc-count {
    font-size: 28px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    line-height: 2;
    color: #333333;
  }
  .desc-com {
    font-size: 12px;
    color: #7e84a3;
  }
}

.total {
  margin-bottom: 40px;
}
.count-item {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
.data-count-name {
  margin-bottom: 6px;
}
.data-count-count {
  font-size: 26px;
}

.count-box1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.side-echart {
  width: 100%;
}

.center-info {
  margin: 0 0 8px 0;
  color: #fff;
}

.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
  margin-left: 10px;
}
.c-info-right {
  font-size: 12px;
  color: #999999;
  display: flex;
  width: fit-content;
}
.no-pb{
  padding-bottom:0 ;
}
</style>