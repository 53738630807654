<template>
  <div :ref="ename" :style="{width,height}"></div>
</template>
      
      <script>
export default {
  props: {
    data: {
      type: Array,
      default:[{
        name:'男',
        value:0
      },{
        name:'女',
        value:0
      }]
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default:'100%'
    },
    height: {
      type: String,
      default:'100%'
    },
  },
  data() {
    return {
      colorList: ["#9E87FF", "#73DDFF", "#fe9a8b", "#F56948", "#9E87FF"],
      myChart: "",
      list: [],
    };
  },
  computed:{
    full(){
      return this.$store.state.menuShow
    }
  },
  watch: {
    full(newval){
      this.myChart.resize()
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener('resize', this.myChart.resize)

  },
  beforeDestroy() {
    // 移除窗口改变监听
    window.removeEventListener('resize', this.myChart.resize)
  },
  methods: {
    getOption() {
      let titleArr = []
       let  seriesArr = []
     let colors = [
        ["#389af4", "#dfeaff"],
        ["#ff8c37", "#ffdcc3"],
        ["#ffc257", "#ffedcc"],
        ["#fd6f97", "#fed4e0"],
        ["#a181fc", "#e3d9fe"],
      ];
      this.data.forEach(function (item, index) {
        titleArr.push({
          text: item.name,
          left: index * 50+18 + "%",
          top: "55%",
          textAlign: "center",
          textStyle: {
            fontWeight: "normal",
            fontSize: "16",
            color: colors[index][0],
            textAlign: "center",
          },
        });
        seriesArr.push({
          name: item.name,
          type: "pie",
          clockWise: false,
          radius: [40, 46],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          center: [index * 50 + 20+ "%", "50%"],
          data: [
            {
              value: item.value || 0,
              label: {
                normal: {
                  formatter: function (params) {
                    return params.value + "%";
                  },
                  position: "center",
                  show: true,
                  textStyle: {
                    fontSize: "20",
                    fontWeight: "bold",
                    color: colors[index][0],
                  },
                },
              },
            },
            {
              value: 100 - item.value,
              name: "invisible",
              itemStyle: {
                normal: {
                  color: colors[index][1],
                },
                emphasis: {
                  color: colors[index][1],
                },
              },
            },
          ],
        });
      });
      return {
        title: titleArr,
        tooltip: {
            trigger: "item",
            backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c",
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
            formatter:  (params)=> {
              return   params.seriesName + '：'+this.data[ params.seriesName =='男' ? 0 : 1].count ;
            },
          },
        series: seriesArr,
      };
    },
    updateOption() {
      this.myChart.setOption(this.getOption());
    },
    getEchartData() {
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.getOption());
      }
    },
  },
};
</script>
      
      <style lang="scss" scoped>
</style>