<template>
  <div>
    <div class="s-item s-head" :style="headStyle">
      <p v-for="(name, index) in listName" :key="index">{{ name }}</p>
    </div>
    <div v-if="data.length > 0">
      <swiper
        class="s-wrap"
        :options="swiperOption"
        :ref="sname"
        @transition-end="handleSwiperSlide"
      >
        <swiper-slide
          class="s-item"
          :style="itemStyle"
          v-for="(item, index) in data"
          :key="index"
        >
          <p v-for="(key, index) in option" :key="index">{{ item[key] }}</p>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name:"scrollList",
  props: {
    listName: {
      type: Array,
    },
    list: {
      type: Array,
    },
    option: {
      type: Array,
    },
    sname: {
      type: String,
    },
    headStyle: {
      type: Object,
    },
    itemStyle: {
      type: Object,
    },
  },
  watch: {
    list: {
      handler(nval, oval) {
        this.data = nval;
        if(this.swiper){
          console.log('start');
          this.swiper.autoplay.start()
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      data: [],
      swiperOption: {
        autoplay: {
          autoplay: 1000, 
          stopOnLastSlide: true,
          disableOnInteraction: false,
        },
        observer:true,
        observeParents:false,
        simulateTouch:false,
        direction: "vertical",
        speed: 1000, 
        slidesPerView: "auto",
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      console.log(this.$refs[this.sname]);
      return this.$refs[this.sname].swiper;
    },
  },
  destroyed() {},
  mounted() {},

  methods: {
    handleSwiperSlide(e) {
      if (this.data.length > 50) {
        this.data.splice(0, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.s-wrap {
  width: 100%;
  height: 350px;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.s-item {
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  color: #434343;
  border-bottom: 1px solid #f8f8f8;
  @media screen and(max-width: 1280px) {
    font-size: 10px;
  }
  p {
    flex: 1;
    text-align: center;
  }
}
.s-head {
  background-color: #f8f8f8;
  font-size: 16px;
  @media screen and(max-width: 1280px) {
    font-size: 12px;
  }
}
</style>